var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PageView",
    { staticClass: "FuelPriceList" },
    [
      _c(
        "div",
        { staticClass: "d-flex align-items-center mb-2" },
        [
          _c("h1", { staticClass: "flex-grow-1" }, [_vm._v(" Fuel price ")]),
          _c(
            "ui-button",
            {
              attrs: { "data-test-id": "update_fuel_price-button" },
              on: { clickbutton: _vm.openFuelPriceForm },
            },
            [_vm._v(" Update fuel price ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _vm.showUpdatePriceSuccess
            ? _c(
                "ui-alert",
                {
                  staticClass: "ml-auto p-0",
                  attrs: { dismissible: "" },
                  on: {
                    dismissAlert: function ($event) {
                      _vm.showUpdatePriceSuccess = false
                    },
                  },
                },
                [
                  _vm._v(
                    " Fuel price updated. The list will be in sync in a few seconds. "
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("MuiAlgoliaList", {
        ref: _vm.fuelPriceList,
        attrs: {
          "export-columns": _vm.contentCells,
          filters: _vm.getOperatorFilter({ attribute: "operator_id" }),
          facets: _vm.fuelTypeListFacets,
          index: _vm.FUEL_PRICES_INDEX,
          "table-config": _vm.contentCells,
          "empty-message": _vm.getNoResultsLabel("fuel price"),
          "is-export-enabled": "",
        },
      }),
      _vm.isUpdateModalOpen
        ? _c("FuelPriceUpdate", {
            on: {
              closeModal: function ($event) {
                _vm.isUpdateModalOpen = false
              },
              "modal-closed": function ($event) {
                _vm.isUpdateModalOpen = false
              },
              "price-update": _vm.onPriceUpdate,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }