<script>
import first from 'lodash/first';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import inRange from 'lodash/inRange';
import map from 'lodash/map';
import { formatPreciseCurrency, sentenceCase, snakeCaseKeys } from '@emobg/web-utils';
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import {
  MuiAlgoliaSelect,
  MuiInputText,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { BUTTON_TYPES } from '@emobg/vue-base';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { CancelButton, GenericModalComponent } from '@/components';
import Currency from '@/domains/FleetInvoicing/utils/currency';

const PRICE_WARNING = {
  min: 0.80,
  max: 1.20,
};

export default {
  name: 'FuelPriceUpdate',
  directives: {
    Validate,
  },
  components: {
    CancelButton,
    GenericModalComponent,
    MuiAlgoliaSelect,
    MuiInputText,
    MuiValidationWrapper,
  },
  data() {
    return {
      operatorUuid: '',
      fuelPrice: '',
      fuelType: '',
      activeFuelPrice: 0,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperatorId: state => get(state, 'operators.active.id'),
      activeOperatorUuid: state => get(state, 'operators.active.uuid'),
    }),
    ...mapState(DOMAINS_MODEL.settings.fuelPriceLog, {
      fuelTypes: state => state.types.data,
      isPriceUpdateLoaded: state => state.priceUpdate.STATUS.LOADED,
      isError: state => state.priceUpdate.STATUS.ERROR,
      isLoading: state => state.priceUpdate.STATUS.LOADING,
      uuid: state => state.priceUpdate.data.uuid,
    }),
    ...mapGetters(DOMAINS_MODEL.app.userAccount, [
      'getOperatorFilter',
    ]),
    priceRatio() {
      return (Currency.serialize(this.fuelPrice) / this.activeFuelPrice) || null;
    },
  },
  watch: {
    fuelTypes(collection) {
      this.$nextTick(() => {
        this.fuelType = first(collection);
      });
    },
    operatorUuid(newOperatorUuid) {
      if (!isEmpty(newOperatorUuid)) {
        this.fetchCurrentPrice();
      }
    },
  },
  created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.PRICE_WARNING = PRICE_WARNING;
    this.BUTTON_TYPES = BUTTON_TYPES;
    this.currencyValidate = [{
      ...Currency.validate,
      message: 'The value should be a valid amount: 0,00',
    }];
    this.formatPreciseCurrency = formatPreciseCurrency;
    this.sentenceCase = sentenceCase;
    this.operatorUuid = this.activeOperatorUuid;
    this.getFuelTypes();
  },
  methods: {
    isNumber,
    inRange,
    map,
    ...mapActions(DOMAINS_MODEL.settings.fuelPriceLog, [
      'postCreateFuelPrice',
      'getFuelTypes',
    ]),
    ...mapMutations(DOMAINS_MODEL.settings.fuelPriceLog, [
      'clearFuelPriceError',
    ]),
    closeModal() {
      this.$emit('closeModal');
    },
    async fetchCurrentPrice() {
      const { hits } = await this.$algolia.fetchIndex(ALGOLIA_INDEXES.csFuelPrices, {
        filters: `end_date: null AND (operator_uuid: ${this.operatorUuid})`,
        hitsPerPage: 100,
        attributesToHighlight: null,
      });
      this.activeFuelPrice = get(first(hits), 'amount', 0);
    },
    async postFuelPrice() {
      const data = {
        csOperatorUuid: this.operatorUuid,
        amount: Currency.serialize(this.fuelPrice),
        type: this.fuelType,
      };
      await this.postCreateFuelPrice({ request: snakeCaseKeys(data, 'postCreateFuelPrice') });
      if (this.isPriceUpdateLoaded) {
        this.$emit('price-update', {
          uuid: this.uuid,
        });
        this.closeModal();
      }
    },
  },
};
</script>
<template>
  <ui-form @submitform="postFuelPrice">
    <MuiValidationWrapper>
      <template #default="{ areAllValid }">
        <GenericModalComponent
          :header="{ isClosable: true }"
          :size="SIZES.small"
          title="Update fuel price"
          v-on="$listeners"
        >
          <template slot="body">
            <div>
              <div class="row">
                <div class="col">
                  <ui-alert
                    v-if="isError"
                    :color="COLORS.danger"
                    dismissible
                    class="py-2 w-100"
                    @dismissAlert="clearFuelPriceError"
                  >
                    We could not update fuel price! Try again.
                  </ui-alert>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <MuiAlgoliaSelect
                    v-model="operatorUuid"
                    v-validate="{
                      isRequired: true,
                    }"
                    :index="ALGOLIA_INDEXES.csOperators"
                    :filters="`id:${activeOperatorId} OR parent_cs_operator_id:${activeOperatorId}`"
                    :title="result => result.name"
                    class="w-100 mb-4"
                    name="operator"
                    no-cache
                    path-value="uuid"
                    placeholder="Select"
                    label="Operator*"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col mb-4">
                  <ui-select
                    :value="fuelType"
                    :options.prop="map(fuelTypes, type => ({ value: type, label: sentenceCase(type) }))"
                    disabled
                    name="fuel_type"
                    label="Fuel type"
                    placeholder="Select"
                    class="w-100"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label class="d-block emobg-font-weight-bold pb-1">
                    Active price (€)
                  </label>
                  <div>
                    <span
                      class="d-inline-block py-2 emobg-caption-1 emobg-font-weight-light"
                      data-test-id="fuel-price-form-active-price"
                    >
                      {{ formatPreciseCurrency(activeFuelPrice) }}
                    </span>
                  </div>
                </div>
                <div class="col">
                  <label class="d-block emobg-font-weight-bold pb-1">
                    New price (€)
                  </label>
                  <MuiInputText
                    v-model="fuelPrice"
                    v-validate="{
                      isPattern: {
                        pattern: /^\d+(\.|,)\d{2}$/,
                        message: 'The value should be a valid amount: 0,00',
                        label: 'The value should be a valid amount: 0,00',
                      },
                    }"
                    class="w-100"
                    name="new_price"
                    placeholder="Fuel price"
                    data-test-id="fuel-price-form-new-price"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <ui-alert
                    v-if="isNumber(priceRatio) && !inRange(priceRatio, PRICE_WARNING.min, PRICE_WARNING.max) && areAllValid"
                    :color="COLORS.moderate"
                    class="py-2"
                  >
                    The difference between Active and new price is quite high.
                    Please make sure it is correct before updating it.
                  </ui-alert>
                </div>
              </div>
            </div>
          </template>
          <template slot="footer">
            <div class="d-flex align-content-center justify-content-end w-100">
              <CancelButton
                class="mr-4"
                @click="closeModal"
              />
              <ui-button
                :loading="isLoading"
                :disabled="!areAllValid || !fuelPrice"
                :type="BUTTON_TYPES.submit"
                narrow
              >
                Save
              </ui-button>
            </div>
          </template>
        </GenericModalComponent>
      </template>
    </MuiValidationWrapper>
  </ui-form>
</template>
