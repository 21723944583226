var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ui-form",
    { on: { submitform: _vm.postFuelPrice } },
    [
      _c("MuiValidationWrapper", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ areAllValid }) {
              return [
                _c(
                  "GenericModalComponent",
                  _vm._g(
                    {
                      attrs: {
                        header: { isClosable: true },
                        size: _vm.SIZES.small,
                        title: "Update fuel price",
                      },
                    },
                    _vm.$listeners
                  ),
                  [
                    _c("template", { slot: "body" }, [
                      _c("div", [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col" },
                            [
                              _vm.isError
                                ? _c(
                                    "ui-alert",
                                    {
                                      staticClass: "py-2 w-100",
                                      attrs: {
                                        color: _vm.COLORS.danger,
                                        dismissible: "",
                                      },
                                      on: {
                                        dismissAlert: _vm.clearFuelPriceError,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " We could not update fuel price! Try again. "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col" },
                            [
                              _c("MuiAlgoliaSelect", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: {
                                      isRequired: true,
                                    },
                                    expression:
                                      "{\n                    isRequired: true,\n                  }",
                                  },
                                ],
                                staticClass: "w-100 mb-4",
                                attrs: {
                                  index: _vm.ALGOLIA_INDEXES.csOperators,
                                  filters: `id:${_vm.activeOperatorId} OR parent_cs_operator_id:${_vm.activeOperatorId}`,
                                  title: (result) => result.name,
                                  name: "operator",
                                  "no-cache": "",
                                  "path-value": "uuid",
                                  placeholder: "Select",
                                  label: "Operator*",
                                },
                                model: {
                                  value: _vm.operatorUuid,
                                  callback: function ($$v) {
                                    _vm.operatorUuid = $$v
                                  },
                                  expression: "operatorUuid",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col mb-4" },
                            [
                              _c("ui-select", {
                                staticClass: "w-100",
                                attrs: {
                                  value: _vm.fuelType,
                                  disabled: "",
                                  name: "fuel_type",
                                  label: "Fuel type",
                                  placeholder: "Select",
                                },
                                domProps: {
                                  options: _vm.map(_vm.fuelTypes, (type) => ({
                                    value: type,
                                    label: _vm.sentenceCase(type),
                                  })),
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col" }, [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "d-block emobg-font-weight-bold pb-1",
                              },
                              [_vm._v(" Active price (€) ")]
                            ),
                            _c("div", [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "d-inline-block py-2 emobg-caption-1 emobg-font-weight-light",
                                  attrs: {
                                    "data-test-id":
                                      "fuel-price-form-active-price",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatPreciseCurrency(
                                          _vm.activeFuelPrice
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "col" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "d-block emobg-font-weight-bold pb-1",
                                },
                                [_vm._v(" New price (€) ")]
                              ),
                              _c("MuiInputText", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: {
                                      isPattern: {
                                        pattern: /^\d+(\.|,)\d{2}$/,
                                        message:
                                          "The value should be a valid amount: 0,00",
                                        label:
                                          "The value should be a valid amount: 0,00",
                                      },
                                    },
                                    expression:
                                      "{\n                    isPattern: {\n                      pattern: /^\\d+(\\.|,)\\d{2}$/,\n                      message: 'The value should be a valid amount: 0,00',\n                      label: 'The value should be a valid amount: 0,00',\n                    },\n                  }",
                                  },
                                ],
                                staticClass: "w-100",
                                attrs: {
                                  name: "new_price",
                                  placeholder: "Fuel price",
                                  "data-test-id": "fuel-price-form-new-price",
                                },
                                model: {
                                  value: _vm.fuelPrice,
                                  callback: function ($$v) {
                                    _vm.fuelPrice = $$v
                                  },
                                  expression: "fuelPrice",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col" },
                            [
                              _vm.isNumber(_vm.priceRatio) &&
                              !_vm.inRange(
                                _vm.priceRatio,
                                _vm.PRICE_WARNING.min,
                                _vm.PRICE_WARNING.max
                              ) &&
                              areAllValid
                                ? _c(
                                    "ui-alert",
                                    {
                                      staticClass: "py-2",
                                      attrs: { color: _vm.COLORS.moderate },
                                    },
                                    [
                                      _vm._v(
                                        " The difference between Active and new price is quite high. Please make sure it is correct before updating it. "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                    _c("template", { slot: "footer" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-content-center justify-content-end w-100",
                        },
                        [
                          _c("CancelButton", {
                            staticClass: "mr-4",
                            on: { click: _vm.closeModal },
                          }),
                          _c(
                            "ui-button",
                            {
                              attrs: {
                                loading: _vm.isLoading,
                                disabled: !areAllValid || !_vm.fuelPrice,
                                type: _vm.BUTTON_TYPES.submit,
                                narrow: "",
                              },
                            },
                            [_vm._v(" Save ")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }