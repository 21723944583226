import { RECORD_TYPES } from '@emobg/motion-ui';
import {
  DATE_FORMAT,
  FALLBACK_MESSAGE,
  formatPreciseCurrency,
  isNilString,
  reformatDateTime,
  sentenceCase,
} from '@emobg/web-utils';
const badgeClass = value => (isNilString(value) ? 'emobg-background-color-success-lightest' : 'emobg-background-color-ground');
const statusString = value => (isNilString(value) ? 'Active' : 'Inactive');
const date = (value, operatorTimezone) => {
  const dateString = isNilString(value) ? FALLBACK_MESSAGE.dash : reformatDateTime(value, DATE_FORMAT.defaultExtended, operatorTimezone);
  return dateString;
};

export const contentCells = operatorTimezone => [
  {
    attributeName: 'operator_name',
    title: 'Operator',
    displayPriority: 1,
    minWidth: 40,
  },
  {
    attributeName: 'type',
    title: 'Fuel Type',
    displayPriority: 2,
    minWidth: 40,
    transformValue: sentenceCase,
  },
  {
    attributeName: 'amount',
    title: 'Price per liter',
    displayPriority: 2,
    transformResult: ({ amount, currency }) => formatPreciseCurrency(amount, currency),
  },
  {
    attributeName: 'start_date',
    displayPriority: 3,
    title: 'Start date',
    transformValue: value => date(value, operatorTimezone),
  },
  {
    attributeName: 'end_date',
    displayPriority: 3,
    title: 'End date',
    transformValue: value => date(value, operatorTimezone),
  },
  {
    attributeName: 'status',
    title: 'Status',
    displayPriority: 3,
    type: RECORD_TYPES.template,
    transformResult: result => (isNilString(result.end_date) ? 'Active' : 'Inactive'),
    template: result => `<span class="d-inline-block pill px-2 ${badgeClass(result.end_date)}">${statusString(result.end_date)}</span>`,
  },
  {
    attributeName: 'creator_name',
    title: 'Updated by',
    displayPriority: 3,
  },
];

export const fuelTypeListFacets = [
  {
    type: 'refinementList',
    props: {
      title: 'Operator',
      attributeName: 'operator_name',
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Fuel type',
      attributeName: 'type',
      transformValue: sentenceCase,
    },
  },
  {
    type: 'refinementOptions',
    props: {
      title: 'Status',
      attributeName: 'end_date',
      showCounter: true,
      options: [
        {
          label: 'Active',
          value: 'null',
        },
        {
          label: 'Inactive',
          value: '-null',
        },
      ],
    },
  },
];
