<script>
import find from 'lodash/find';
import get from 'lodash/get';
import { mapGetters, mapState } from 'vuex';
import { MuiAlgoliaList } from '@emobg/motion-ui';
import { DELAY, TIME_ZONE } from '@emobg/web-utils';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { PageView } from '@/components';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { getNoResultsLabel, refreshAlgoliaStore } from '@/utils';

import { contentCells, fuelTypeListFacets } from './config/algoliaTableConfig';
import FuelPriceUpdate from '../Update/FuelPriceUpdate';

export default {
  name: 'FuelPriceList',
  components: {
    FuelPriceUpdate,
    MuiAlgoliaList,
    PageView,
  },
  data() {
    return {
      isUpdateModalOpen: false,
      showUpdatePriceSuccess: false,
      isListInSync: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorName: state => get(state, 'operators.active.name'),
      operatorTimezone: state => get(state, 'operators.active.timezone') || TIME_ZONE.default,
    }),
    ...mapGetters(DOMAINS_MODEL.app.userAccount, [
      'getOperatorFilter',
    ]),
  },
  created() {
    this.fuelPriceList = 'fuel-price-list';
    this.FUEL_PRICES_INDEX = ALGOLIA_INDEXES.csFuelPrices;
    this.contentCells = contentCells(this.operatorTimezone);
    this.fuelTypeListFacets = fuelTypeListFacets;
  },
  methods: {
    getNoResultsLabel,
    refreshAlgoliaStore,
    async syncListWithSubmittedPrice(submittedPriceData, iteration = 0) {
      const { hits } = await this.$algolia.fetchIndex(ALGOLIA_INDEXES.csFuelPrices, {
        filters: this.getOperatorFilter({ attribute: 'operator_id' }),
        hitsPerPage: 100,
        attributesToHighlight: null,
      });
      this.isListInSync = !!find(hits, submittedPriceData);
      if (this.isListInSync || iteration > 3) {
        this.showUpdatePriceSuccess = false;
        this.refreshAlgoliaStore(this.$refs[this.fuelPriceList]);
        return;
      }
      const currentIteration = iteration + 1;
      setTimeout(this.syncListWithSubmittedPrice, DELAY.extraLong, submittedPriceData, currentIteration);
    },
    openFuelPriceForm() {
      this.isUpdateModalOpen = true;
    },
    onPriceUpdate(submittedPriceData) {
      this.isListInSync = false;
      this.showUpdatePriceSuccess = true;
      this.syncListWithSubmittedPrice(submittedPriceData);
    },
  },
};
</script>
<template>
  <PageView class="FuelPriceList">
    <div class="d-flex align-items-center mb-2">
      <h1 class="flex-grow-1">
        Fuel price
      </h1>
      <ui-button
        data-test-id="update_fuel_price-button"
        @clickbutton="openFuelPriceForm"
      >
        Update fuel price
      </ui-button>
    </div>
    <div class="d-flex">
      <ui-alert
        v-if="showUpdatePriceSuccess"
        dismissible
        class="ml-auto p-0"
        @dismissAlert="showUpdatePriceSuccess = false"
      >
        Fuel price updated. The list will be in sync in a few seconds.
      </ui-alert>
    </div>
    <MuiAlgoliaList
      :ref="fuelPriceList"
      :export-columns="contentCells"
      :filters="getOperatorFilter({ attribute: 'operator_id' })"
      :facets="fuelTypeListFacets"
      :index="FUEL_PRICES_INDEX"
      :table-config="contentCells"
      :empty-message="getNoResultsLabel('fuel price')"
      is-export-enabled
    />
    <FuelPriceUpdate
      v-if="isUpdateModalOpen"
      @closeModal="isUpdateModalOpen = false"
      @modal-closed="isUpdateModalOpen = false"
      @price-update="onPriceUpdate"
    />
  </PageView>
</template>
